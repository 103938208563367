import React, { FC } from 'react';
import SectionTitle from '../../../components/common/SectionTitle';
import Links from '../../../components/pagesComponents/CaseStudy/Links';
import ListItem from '../../../components/pagesComponents/CaseStudy/ListItem';
import { TText } from '../../../types/types';
import FreshiiLayouts from '../../../components/PagesLayouts/FreshiiLayouts';
import FixedButton from '../../../components/common/FixedButton';

const OtherIndustriesRelevance: FC = () => {
    const industriesText: TText = [
        {
            id: 0,
            text:
                'Our experience with implementing product customization logic can benefit the fashion industry, where customers expect a wide range of customization options to personalize their clothing items.',
            margin: 'mt',
        },
        {
            id: 1,
            text:
                'Implementing streamlined ordering and promotions can be valuable for e-commerce businesses seeking to enhance customer experience and increase sales through targeted advertisements and offers.',
        },
        {
            id: 2,
            text:
                'Fraud prevention logic, secure payment processing, and single sign-on (SSO) are crucial components of any industry that requires safe and reliable transactions, including finance and healthcare.',
        },
        {
            id: 3,
            text:
                'Implementing a new responsive UI design is essential for any company seeking to provide a modern and user-friendly interface for their customers. This approach can benefit education and entertainment industries that rely on engaging and intuitive interfaces to deliver content and services.',
        },
        {
            id: 4,
            text:
                'Integrating with analytics platforms can provide valuable insights into customer behavior and improve business strategies. This approach is relevant for any industry that needs data to make informed business decisions, including the hospitality and travel sectors.',
        },
    ];

    return (
        <FreshiiLayouts>
            <div className="statflo-case-study__text statflo-case-study__text--large">
                <SectionTitle type="primary" title="Others industries relevance" />
                {industriesText.map((industry) => (
                    <ListItem key={industry.id} margin={industry.margin}>
                        {industry.text}
                    </ListItem>
                ))}
                <Links />
            </div>
            <FixedButton/>
        </FreshiiLayouts>
    );
};

export default OtherIndustriesRelevance;
